<template>
    <div class="order-list">
        <order-content :orders="list"></order-content>
    </div>
</template>

<script>
import OrderContent from "@/components/Order/OrderContent";
import { orderStatus } from "@/config";
import { mapGetters, mapActions } from "vuex";

export default {
    data() {
        return {
            list: []
        };
    },
    components: {
        OrderContent
    },
    computed: {
        ...mapGetters({ user: "user/user" })
    },
    methods: {
        async populateTabs() {
            this.list = await this.$store.dispatch("crud/get", {
                api: "orders/my"
            });
        }
    },
    created() {
        this.populateTabs();
    }
};
</script>
<style lang="less">
.order-list {
    .md-tabs .md-tabs-navigation {
        background: transparent;
    }

    .md-tabs .md-tab-header {
        color: #666 !important;
    }

    .md-tabs .md-tab-header.md-active {
        color: #2196f3 !important;
    }

    .md-tabs .md-tabs-navigation .md-tab-indicator {
        background: #2196f3;
    }
}
</style>