<template>
    <div class="order-content" style="flex: 1; overflow: hidden">
        <md-table-card v-if="orders">
            <form @submit.prevent="load" action>
                <div class="list-toolbar" :class="{ mobile: isMobile }">
                    <h1 class="md-title" style="flex: inherit">{{ isManage ? "Manage Bookings" : "My Orders" }}</h1>
                    <router-link class="md-icon-button md-raised md-dense md-primary" tag="md-button" to="/admin/orders/new" v-if="isManage">
                        <md-icon>add</md-icon>
                    </router-link>
                    <template v-if="!isMobile">
                        <div style="flex: 1"></div>
                        <div class="title-select">
                            <label for>TimeZone</label>
                            <md-input-container style="width: 130px">
                                <span class="val">{{ tz }}</span>
                                <md-input type="hidden"></md-input>
                            </md-input-container>
                        </div>
                        <div class="title-select">
                            <label for>Status</label>
                            <md-input-container style="width: 110px">
                                <md-select v-model="statusRange">
                                    <md-option value="All">All</md-option>
                                    <md-option :key="index" :value="status" v-for="(status, index) in orderStatuses">{{ status }}</md-option>
                                </md-select>
                            </md-input-container>
                        </div>
                        <div class="title-select">
                            <md-input-container>
                                <md-select v-model="dateRangeType">
                                    <md-option value="start">Start Date</md-option>
                                    <md-option value="end">End Date</md-option>
                                </md-select>
                            </md-input-container>
                            <date-range-picker v-model="dateRange"></date-range-picker>
                        </div>
                    </template>
                    <template v-else>
                        <div style="width:100%;"></div>
                        <md-list style="flex:1;">
                            <md-list-item>
                                <span>Filters</span>
                                <md-list-expand>
                                    <md-list>
                                        <md-list-item class="md-inset">
                                            <label for>TimeZone</label>
                                            <md-input-container style="width: 130px">
                                                <span class="val">{{ tz }}</span>
                                                <md-input type="hidden"></md-input>
                                            </md-input-container>
                                        </md-list-item>
                                        <md-list-item class="md-inset">
                                            <label for>Status</label>
                                            <md-input-container style="width: 110px">
                                                <md-select v-model="statusRange">
                                                    <md-option value="All">All</md-option>
                                                    <md-option :key="index" :value="status" v-for="(status, index) in orderStatuses">{{ status }}</md-option>
                                                </md-select>
                                            </md-input-container>
                                        </md-list-item>
                                        <md-list-item class="md-inset">
                                            <md-input-container>
                                                <md-select v-model="dateRangeType">
                                                    <md-option value="start">Start Date</md-option>
                                                    <md-option value="end">End Date</md-option>
                                                </md-select>
                                            </md-input-container>
                                            <date-range-picker v-model="dateRange"></date-range-picker>
                                        </md-list-item>
                                    </md-list>
                                </md-list-expand>
                            </md-list-item>
                        </md-list>
                        <div style="width:100%;"></div>
                    </template>
                    <div class="title-select" style="border-bottom: 0px">
                        <md-button class="md-icon-button">
                            <md-icon>search</md-icon>
                        </md-button>
                        <md-input-container md-inline :style="{ width: isMobile ? '200px' : '150px' }">
                            <md-input placeholder="Search..." v-model="search"></md-input>
                        </md-input-container>
                    </div>
                    <div class="title-select" style="border-bottom: 0px">
                        <md-button class="md-primary md-raised" type="submit">Submit</md-button>
                    </div>
                </div>
            </form>
            <md-table :class="{ isManage }" @sort="handleSort" md-sort="rentalStartDate" md-sort-type="asc" v-show="orders.length && ready">
                <md-table-header>
                    <md-table-row>
                        <!-- <md-table-head> -->
                        <md-table-cell>
                            <md-button v-if="isManage" @click="setOrderExpandAll" class="md-icon-button">
                                <md-icon v-if="!expandAll">add</md-icon>
                                <md-icon v-if="expandAll">remove</md-icon>
                            </md-button>
                        </md-table-cell>
                        <!-- </md-table-head> -->
                        <md-table-cell>Order No</md-table-cell>
                        <!-- <md-table-cell md-sort-by="rentalStartDate">Start Date</md-table-cell> -->
                        <!-- <md-table-cell>End Date</md-table-cell> -->
                        <md-table-cell v-if="isManage">Customer</md-table-cell>
                        <md-table-cell v-if="isManage">Company</md-table-cell>
                        <md-table-cell>Vendor</md-table-cell>
                        <md-table-cell v-if="canViewAmount">Amount</md-table-cell>
                        <md-table-cell v-if="canViewAmount">Deposit</md-table-cell>
                        <md-table-cell v-if="canViewAmount">Balance</md-table-cell>
                        <md-table-cell>Order Status</md-table-cell>
                        <md-table-cell>Payment Status</md-table-cell>
                    </md-table-row>
                </md-table-header>
                <md-table-body>
                    <template v-for="(order, index) in orders">
                        <md-table-row :key="index" :md-item="order">
                            <md-table-cell>
                                <md-button @click="setOrderExpand(order)" class="md-icon-button">
                                    <md-icon v-if="!order.expand">add</md-icon>
                                    <md-icon v-if="order.expand">remove</md-icon>
                                </md-button>
                            </md-table-cell>
                            <md-table-cell>
                                <router-link :to="(isManage ? '/admin/orders/editDetail/' : '/admin/orders/detail/') + order._id">{{ order.orderNo }}</router-link>
                            </md-table-cell>
                            <md-table-cell v-if="isManage">
                                <router-link :to="'/admin/users/' + order.user_id" v-if="order.user_name">{{ order.user_name }}</router-link>
                            </md-table-cell>
                            <md-table-cell v-if="isManage">
                                <router-link :to="'/admin/users/' + order.user_id" v-if="order.user_company">{{ order.user_company }}</router-link>
                            </md-table-cell>
                            <md-table-cell>
                                <span>{{ order.vendor_name }}</span>
                            </md-table-cell>
                            <md-table-cell v-if="canViewAmount">
                                <span>{{ (order.amount.total + order.amount.shipping + order.amount.offhour + order.amount.securityDeposit) | currency }}</span>
                            </md-table-cell>
                            <md-table-cell v-if="canViewAmount">
                                <span>{{ order.paid || 0 | currency }}</span>
                            </md-table-cell>
                            <md-table-cell v-if="canViewAmount">
                                <span v-if="order.status != 'Cancelled'">{{
                                    order.amount && (order.amount.total + order.amount.shipping + order.amount.offhour + order.amount.securityDeposit - (order.paid || 0)) | currency
                                }}</span>
                                <span v-if="order.status == 'Cancelled'">{{ 0 | currency }}</span>
                            </md-table-cell>
                            <md-table-cell>
                                <span>{{ order.status }}</span>
                            </md-table-cell>
                            <md-table-cell>
                                <span>{{ order.paymentStatus }}</span>
                            </md-table-cell>
                        </md-table-row>
                        <md-table-row :key="'s' + index" :md-item="order" class="sub-table-row" v-if="order.expand">
                            <md-table @sort="handleSort">
                                <md-table-header>
                                    <md-table-row>
                                        <md-table-head>Product</md-table-head>
                                        <md-table-head md-sort-by="items.rentalStartDate">Start Date</md-table-head>
                                        <md-table-head>End Date</md-table-head>
                                        <md-table-head v-if="canViewAmount">Sub Amount</md-table-head>
                                        <md-table-head v-if="canViewAmount">Sub Deposit</md-table-head>
                                        <md-table-head v-if="canViewAmount">Sub Balance</md-table-head>
                                    </md-table-row>
                                </md-table-header>
                                <md-table-body>
                                    <md-table-row :key="ix" v-for="(item, ix) in order.items">
                                        <md-table-cell>
                                            <span>{{ item.product.name }}</span>
                                        </md-table-cell>
                                        <md-table-cell>
                                            <span v-if="!item.product.rental">{{ item.rentalStartDate | date }}</span>
                                            <span v-if="item.product.rental">{{ item.rentalStartDate | date }}</span>
                                        </md-table-cell>
                                        <md-table-cell>
                                            <span v-if="item.product.rental">{{ item.rentalEndDate | date }}</span>
                                        </md-table-cell>
                                        <md-table-cell v-if="canViewAmount">
                                            <span>{{ item.subTotal | currency }}</span>
                                        </md-table-cell>
                                        <md-table-cell v-if="canViewAmount">
                                            <span>{{ item.subPaid || 0 | currency }}</span>
                                        </md-table-cell>
                                        <md-table-cell v-if="canViewAmount">
                                            <span>{{ (item.subTotal - (item.subPaid || 0)) | currency }}</span>
                                        </md-table-cell>
                                    </md-table-row>
                                </md-table-body>
                            </md-table>
                        </md-table-row>
                    </template>
                </md-table-body>
                <md-table-body v-if="canViewAmount">
                    <tr class="md-table-row" style="background: #eee; height: 50px">
                        <td v-if="isManage">
                            <span>Total</span>
                        </td>
                        <td>
                            <span></span>
                        </td>
                        <td>
                            <span></span>
                        </td>
                        <td>
                            <span></span>
                        </td>
                        <td>
                            <span>{{ total | currency }}</span>
                        </td>
                        <td>
                            <span>{{ paidTotal | currency }}</span>
                        </td>
                        <td>
                            <span>{{ (total - paidTotal) | currency }}</span>
                        </td>
                        <td>
                            <span></span>
                        </td>
                        <td>
                            <span></span>
                        </td>
                    </tr>
                </md-table-body>

                <md-table-pagination
                    :md-page="page.pageNo"
                    :md-page-options="[5, 10, 20, 50]"
                    :md-size="page.pageSize"
                    :md-total="page.total"
                    @pagination="onPagination"
                    md-label="Rows"
                    md-separator="of"
                ></md-table-pagination>
            </md-table>
            <h2 style="text-align: center" v-show="!orders.length && ready">Sorry, no results found!</h2>
        </md-table-card>
        <md-card class="hcenter" v-else>
            <h3>No Orders</h3>
        </md-card>
    </div>
</template>

<script>
import DateRangePicker from "@/components/DateRangePicker";
import { mapGetters } from "vuex";
import { orderStatuses, paymentStatuses } from "@/config";
import moment from "moment-timezone";
export default {
    props: ["isManage"],
    components: {
        DateRangePicker
    },
    data() {
        return {
            expandAll: false,
            orders: [],
            total: 0,
            paidTotal: 0,
            page: {
                pageSize: 5
            },

            search: "",
            dateRange: "This month", //array | string
            dateRangeType: "start", //start end
            statusRange: "All Active",

            ready: false,
            sort: undefined
        };
    },
    computed: {
        ...mapGetters({
            tz: "tz",
            hasRole: "user/hasRole",
            hasPermission: "user/hasPermission",
            user: "user/user"
        }),
        paymentStatuses() {
            return paymentStatuses;
        },
        orderStatuses() {
            return orderStatuses;
        },
        canViewAmount() {
            // return false;
            return this.hasRole("vendor") || (this.user.role == "staff" && this.hasPermission("view_order_list_amount"));
        },
        isMobile() {
            return window.screen.width <= 1000;
        }
    },
    watch: {
        orders() {
            setTimeout(() => {
                this.ready = true;
            }, 0);
        }
    },
    methods: {
        async load() {
            this.loadOrders();
            this.loadOrdersTotal();
        },
        async loadOrders() {
            let result = await this.$store.dispatch("crud/get", {
                api: this.isManage ? "orders" : "orders/my",
                params: {
                    q: this.search,
                    dateRangeType: this.dateRangeType,
                    dateRangeStart: typeof this.dateRange == "object" && this.dateRange[0] ? moment(this.dateRange[0]).toDate() : undefined,
                    dateRangeEnd:
                        typeof this.dateRange == "object" && this.dateRange[1]
                            ? moment(this.dateRange[1])
                                  .endOf("day")
                                  .toDate()
                            : undefined,
                    status: this.statusRange,
                    sort: this.sort || undefined,
                    ...this.page
                }
            });

            let orders = result.data;
            let page = result.page;

            // set default
            orders.forEach(order => {
                order.expand = false;
            });
            this.orders = orders;
            this.page = page;
        },
        async loadOrdersTotal() {
            if (this.isManage) {
                let result = await this.$store.dispatch("crud/get", {
                    api: "orders/total",
                    params: {
                        q: this.search,
                        dateRangeType: this.dateRangeType,
                        dateRangeStart: typeof this.dateRange == "object" && this.dateRange[0] ? moment(this.dateRange[0]).toDate() : undefined,
                        dateRangeEnd:
                            typeof this.dateRange == "object" && this.dateRange[1]
                                ? moment(this.dateRange[1])
                                      .endOf("day")
                                      .toDate()
                                : undefined,
                        status: this.statusRange
                    }
                });
                this.total = result.total;
                this.paidTotal = result.paidTotal;
            }
        },
        handleSort(sort) {
            console.info(sort);
            if (sort.type == "asc") {
                this.sort = sort.name;
            } else {
                this.sort = "-" + sort.name;
            }
            this.loadOrders();
        },
        onPagination({ page, size }) {
            this.page.pageNo = page;
            this.page.pageSize = size;
            this.loadOrders();
        },
        setOrderExpand(order) {
            this.$set(order, "expand", !order.expand);
        },
        setOrderExpandAll() {
            this.expandAll = !this.expandAll;
            this.orders.forEach(order => (order.expand = this.expandAll));
        }
    },
    mounted() {
        let startdate = this.$route.query.defaultstartdate;

        if (startdate) {
            this.dateRangeType = "start";
            this.dateRange = [moment(startdate), moment(startdate)];
        }
        setTimeout(() => {
            this.load();
        }, 0);
    }
};
</script>
<style lang="less">
.order-content {
    // exactly the same to list.vue
    .list-toolbar {
        display: flex;
        align-items: center;
        padding: 0 20px;
        justify-content: flex-end;
        &.mobile {
            flex-wrap: wrap;
            justify-content: flex-start;
            padding-top: 10px;
            .title-select {
                flex: 1;
            }
            .md-list-item.md-inset .md-list-item-container {
                padding-left: 0;
            }
        }
    }

    .title-select {
        display: flex;
        align-items: center;
        margin-right: 10px;

        label {
            margin-right: 10px;
            color: #aaa;
        }
        .md-select {
            min-width: auto;
            line-height: 20px;
        }
        .val {
            line-height: 31px;
        }
    }
    .md-table {
        table {
            display: block;
            min-width: 1200px;
        }
        .md-table-header {
            display: block;
            background: #eee;
        }
        .md-table-body {
            display: block;
        }

        .md-table-row {
            height: auto;
            display: flex;
            justify-content: space-around;
            align-items: center;
            height: auto;
            white-space: nowrap;
            &:hover {
                background: #eee;
                td {
                    background-color: transparent !important;
                }
            }
            .md-table-cell {
                height: auto;
                min-height: 40px;
                .md-table-cell-container {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
                }
            }
            td,
            th {
                flex: 1;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 120px;
                img {
                    max-height: 40px;
                }
            }

            &.sub-table-row {
                padding: 30px;
                height: auto;
                justify-content: flex-start;

                &:hover {
                    background: inherit;
                }
                .md-table {
                    flex: 1;
                    .md-table-header {
                        background: #f7f7f7;
                        tr {
                            background: #f7f7f7;
                        }
                    }
                    .md-table-body {
                        background: #f7f7f7;
                    }
                }
            }

            &.md-selected {
                .md-table-cell {
                    background-color: inherit;
                }
            }
        }
    }
}
</style>
